
/**
 * Делает прокрутку страницы вверх до сообщения о незаполненном поле в форме.
 */
const scrollToErrMessage = () => {
  requestAnimationFrame(() => {
    const spanErrMessage = document.querySelector('span[class*="error-msg"]:not(.form_error-msg-company_2R79)');

    if (spanErrMessage) {
      spanErrMessage.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  });
};

export default scrollToErrMessage;
