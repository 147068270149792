import { useState, useEffect } from 'react';

const useLoadData = (loadFunction, dependencies = []) => {
  const [{ loading, data, error }, setState] = useState({
    loading: true,
    data: null,
    error: null,
  });

  async function loadData() {
    setState({ loading: true, data: null, error: null });
    try {
      const result = await loadFunction();
      setState({ loading: false, data: result, error: null });
    } catch (err) {
      setState({ loading: false, data: null, error: err });
    }
  }

  useEffect(() => {
    loadData();
  }, dependencies);

  return { loading, data, error };
};

export default useLoadData;
