import renderComponents from './components';

const NAME = 'sw-vacation';
const DEFAULTLABELSEARCHINPUT = 'Введите email, имя или телефон пользователя';

class VacationModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const filterService = context.getService('Filter');
    const companiesService = context.getService('Companies');
    filterService.setSearchFilter(DEFAULTLABELSEARCHINPUT, '');

    this.page = renderComponents(this.box, {
      filterService,
      companiesService,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, VacationModule);
}
