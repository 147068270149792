import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Loading } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';
import { TRIPSLABELS } from '../../../../bi/constants/trips';

import AjaxButton from '../../../../components/ajaxButton';

import DisabledAccountDialog from './dialogs/disabledAccount';
import PlanFee from './dialogs/planFee';
import TripFilters from './filters';
import Trip from './trip';

import styles from '../styles/account.module.scss';

const ALLTRIPSINDEX = 0;

const LABEL_PLAN_FEE = 'Добавить первую абонплату';

class Trips extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    handlerClearCache: PropTypes.func.isRequired,
    onNewTrip: PropTypes.func.isRequired,
    getXmlVisibilityFlag: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { accountService, companyService } = props;

    this.state = {
      trips: companyService.get(),
      account: accountService.get().account,

      filteredTrips: [],
      filters: null,
      waitingResponse: false,
      showLoadTripButton: true,
      loading: true,
      showDisabledAccountDialog: false,
      showOpenPlanFeeDialog: false,
      isScrollToTrips: false,
      projects: [],
      companies: [],
      tags: [],
      analytics: [],
      departments: [],
      initComponent: true,
    };
  }

  componentDidMount() {
    const { companyService, companyId, accountId, accountService, tripService } = this.props;
    accountService.loadAccount(accountId).then(({ account: { Companies } }) => this.setState({ Companies }));

    this.unsubscribe = companyService.subscribe(this.update);

    companyService.getDepartments(accountId).then(departments => this.setState({ departments }));
    companyService.getProjects(accountId).then(projects => this.setState({ projects }));
    companyService.getTags(accountId).then((tags) => {
      const mappedTripTags = tags.map(({ Id, Value }) => ({ value: Id, label: Value }));
      this.setState({ tags: mappedTripTags });
    });
    tripService.getAnalytics(accountId).then(analytics => this.setState({ analytics }));
    companyService.loadTrips(companyId).then(() => this.setState({ initComponent: false }));
    this.setScrollFlag();
    setTimeout(this.handleScroll, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    const { accountService, accountId } = this.props;
    const { Id } = prevState.account;

    if (Id !== accountService.get().account.Id && Id !== accountId) {
      this.setAccount();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ trips }) => this.setState({
    loading: false,
    filteredTrips: trips,
  });

  setAccount = () => this.setState({
    account: this.props.accountService.get().account,
  });

  setScrollFlag = () => {
    const [key, value] = location.search.slice(1).split('=');
    if (!!key && key === 'toScroll') {
      this.setState({ isScrollToTrips: Boolean(value) });
    } else {
      this.setState({ isScrollToTrips: false });
    }
  };

  handleScroll = () => {
    const { isScrollToTrips } = this.state;
    if (!isScrollToTrips) {
      return;
    }
    window.scroll({ top: document.body.clientHeight - 40, left: 0, behavior: 'smooth' });
  }

  toggleDisabledAccountDialog = () => this.setState({
    showDisabledAccountDialog: !this.state.showDisabledAccountDialog,
  });

  toggleOpenPlanFee = () => this.setState({
    showOpenPlanFeeDialog: !this.state.showOpenPlanFeeDialog,
  });

  handleFilterTrips = (filteredTrips, filters) => {
    const filtersLength = filters.cities.length ||
      filters.employees.length ||
      filters.services.length ||
      filters.statuses.length ||
      filters.startDate ||
      filters.endDate;
    const trips = filtersLength ? filteredTrips : this.state.trips;

    this.setState({
      filteredTrips: trips,
      filters,
    });
  };

  handleAddNewTrip = () => {
    const { onNewTrip } = this.props;
    const { account: { Enabled } } = this.state;

    if (!Enabled) {
      return this.toggleDisabledAccountDialog();
    }

    return onNewTrip();
  };

  handleAddPlanFee = (data) => {
    const { accountService, companyService, accountId, companyId } = this.props;

    const preparedDate = {
      ...data,
      AccountId: accountId,
      CompanyId: companyId,
    };

    return companyService.addPlanFee(preparedDate).then(() => accountService.updateOnlyAccount(accountId));
  };

  loadTrips = () => {
    const { companyService, companyId } = this.props;

    this.setState({
      waitingResponse: true,
    }, () => {
      companyService.loadTrips(companyId, ALLTRIPSINDEX).then(() => {
        this.setState({
          showLoadTripButton: false,
          waitingResponse: false,
        });
      });
    });
  };

  renderDisabledAccountDialog = () => (
    <DisabledAccountDialog onClose={ this.toggleDisabledAccountDialog } />
  );

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading
        size='large'
      />
    </div>
  );

  renderHeader = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-8'] }>
        <label>#</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Дата начала</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Дата окончания</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Название</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Услуги</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Сотрудники</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Сумма</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Статус</label>
      </div>
    </div>
  );

  renderList = (list) => {
    if (list.length) {
      const {
        companyId,
        accountId,
      } = this.props;

      const { waitingResponse, showLoadTripButton } = this.state;

      const tripsList = list.map(item => (
        <Trip
          key={ item.Id }
          item={ item }
          companyId={ companyId }
          accountId={ accountId }
        />
      ));

      if (showLoadTripButton) {
        tripsList.push(
          <div className={ styles['center-button'] } key={ 'loadTripsButton' }>
            <div className={ `${styles.action} ${styles['load-all']}` }>
              <AjaxButton
                loading={ waitingResponse }
                theme='flat'
                label='ЗАГРУЗИТЬ ВСЕ ПОЕЗДКИ'
                onClick={ this.loadTrips }
              />
            </div>
          </div>
        );
      }

      return (
        <div className={ styles.form }>
          { this.renderHeader() }
          { tripsList }
        </div>
      );
    }

    return null;
  };

  renderEmptyList = () => <div className={ `empty-list ${styles['empty-list']}` }>Нет поездок</div>;

  renderPlanFeeDialog = () => {
    const { companyId, accountService } = this.props;
    const { LastPlanFeeHistories } = accountService.get().account;

    return (
      <PlanFee
        companyId={ companyId }
        lastPlanFeeHistories={ LastPlanFeeHistories }
        onAddPlanFee={ this.handleAddPlanFee }
        onClose={ this.toggleOpenPlanFee }
        labelHeader={ LABEL_PLAN_FEE }
        placeholderLabel={ TRIPSLABELS.PLANFEE }
      />
    );
  };

  render() {
    const {
      companyService,
      handlerClearCache,
      accountId,
      featureFlagsService,
      getXmlVisibilityFlag,
      companyId,
    } = this.props;

    const {
      filteredTrips,
      loading,
      showDisabledAccountDialog,
      showOpenPlanFeeDialog,
      departments,
      projects,
      Companies,
      tags,
      analytics,
      initComponent,
    } = this.state;
    const trips = companyService.get().trips;

    const html = filteredTrips && filteredTrips.length > 0 ? this.renderList(filteredTrips) : this.renderEmptyList();
    const disabledAccountDialogHtml = showDisabledAccountDialog ? this.renderDisabledAccountDialog() : null;
    const planFeeDialogHtml = showOpenPlanFeeDialog && this.renderPlanFeeDialog();

    return loading || initComponent ? this.renderLoading() : (
      <div className={ styles['trip-company'] }>
        <div className={ styles.panel }>
          <TripFilters
            departments={ departments }
            companies={ Companies }
            projects={ projects }
            trips={ trips }
            companyService={ companyService }
            accountTripTags={ tags }
            analytics={ analytics }
            accountId={ accountId }
            filteredTrips={ filteredTrips }
            onChange={ this.handleFilterTrips }
            getXmlVisibilityFlag={ getXmlVisibilityFlag }
            featureFlagsService={ featureFlagsService }
            companyId={ companyId }
          />
        </div>
        <div className={ styles.panel }>
          <div className={ styles.title }>
            <div className={ styles['panel-name'] }>Поездки</div>
            <div>
              <Button
                label={ TRIPSLABELS.CACHE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ handlerClearCache }
              />
            </div>
            <div>
              <Button
                label={ TRIPSLABELS.FEE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleOpenPlanFee }
              />
            </div>
            <div>
              <Button
                label={ TRIPSLABELS.TRIP }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.handleAddNewTrip }
              />
            </div>
          </div>
          { html }
          { disabledAccountDialogHtml }
          { planFeeDialogHtml }
        </div>
      </div>
    );
  }
}

export default Trips;
