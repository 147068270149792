import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Button } from 'sw-ui';

import { CancelVoucherDialog } from '../../components/CancelVoucherDialog';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const LABELS = {
  CONFIRM_BUTTON: 'Отменить бронирование',
};

class VipHallCancel extends Component {
  static propTypes = {
    tripService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      cancelVoucherDialog: {
        isRefundDialog,
        isAprooveDialog,
        isResultDialog,
        loadingCancelDialog,
        dialogResultMsg,
        amountCancelVoucher,
        cancelVoucherInputValue,
      },
    } = props.tripService.get();

    this.state = {
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
    };
  }

  componentDidMount() {
    const { tripService } = this.props;

    this.unsubscribe = tripService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = ({
    cancelVoucherDialog: {
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
    },
  }) => this.setState({
    isRefundDialog,
    isAprooveDialog,
    isResultDialog,
    loadingCancelDialog,
    dialogResultMsg,
    amountCancelVoucher,
    cancelVoucherInputValue,
  });

  renderCancelButton = () => {
    const { tripService: { setRefundDialog } } = this.props;

    return (
      <Button
        theme='second'
        className={ styles.cancel_btn }
        onClick={ () => setRefundDialog(true) }
      >
        { LABELS.CONFIRM_BUTTON }
      </Button>
    );
  };

  render() {
    const {
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
    } = this.state;
    const {
      tripService: {
        setRefundDialog,
        setAprooveDialog,
        setResultVoucherDialog,
        getRefundVoucher,
        cancelVoucher,
        setCancelVoucherInputValue,
      },
    } = this.props;

    return (
      <div>
        { this.renderCancelButton() }
        <CancelVoucherDialog
          setRefundDialog={ setRefundDialog }
          isRefundDialog={ isRefundDialog }
          setAprooveDialog={ setAprooveDialog }
          isAprooveDialog={ isAprooveDialog }
          isResultDialog={ isResultDialog }
          setResultVoucherDialog={ setResultVoucherDialog }
          getRefundVoucher={ getRefundVoucher }
          cancelVoucher={ cancelVoucher }
          loadingCancelDialog={ loadingCancelDialog }
          dialogResultMsg={ dialogResultMsg }
          amountCancelVoucher={ amountCancelVoucher }
          setCancelVoucherInputValue={ setCancelVoucherInputValue }
          cancelVoucherInputValue={ cancelVoucherInputValue }
          serviceType={ SERVICETYPE.VIP_HALL }
        />
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <VipHallCancel
      tripService={ opts.tripService }
      filterService={ opts.filterService }
      toTrip={ opts.toTrip }
      moveToCompany={ opts.moveToCompany }
    />
  );

  return root;
};

export default renderComponents;
