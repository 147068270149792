import moment, { isMoment } from 'moment';

import { DEFAULTDATE } from '../constants/time';

const SMARTWAYBIRTH = '2016-03-18';

const getCurrentDate = () => moment();

const getCurrentDatePlusYear = () => moment().add(1, 'year');

const getStartDateOfCurrentMonth = () => moment().startOf('month');

const getStartDateOfLastMonth = () => moment().add(-1, 'month').startOf('month');

const getEndDateOfLastMonth = () => moment().add(-1, 'month').endOf('month');

const getSmartwayBirthDate = () => moment(SMARTWAYBIRTH);

const getDateObjFromStr = str => moment(str);

const getCurrentYear = () => moment().year();

const formatDate = (value, pattern = DEFAULTDATE) => moment(value).format(pattern);

const momentDate = value => moment(value);

export {
  getCurrentDate,
  getCurrentDatePlusYear,
  getStartDateOfCurrentMonth,
  getStartDateOfLastMonth,
  getEndDateOfLastMonth,
  getSmartwayBirthDate,
  getDateObjFromStr,
  getCurrentYear,
  formatDate,
  momentDate,
  isMoment,
};
