// eslint-disable-next-line
import renderComponents from './components';

const NAME = 'sw-vipHallCancel';

class VipHallCancelModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const tripService = context.getService('Trip');

    this.page = renderComponents(this.box, { tripService });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, VipHallCancelModule);
}
