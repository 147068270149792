import React from 'react';
import PropTypes from 'prop-types';

import useDisplayingDocumentsData from './hooks/useDisplayingDocumentsData';

import styles from './styles.module.css';

const LABELS = {
  TITLE: 'Документы',
  FULL_NAME: 'Полное имя',
  FULL_NAME_TRANSLITERATION: 'Полное имя транслитом',
  NUMBER: 'Номер паспорта',
};

const Documents = ({ documents }) => {
  const docs = useDisplayingDocumentsData(documents);

  if (!docs.length) {
    return null;
  }

  return (
    <div className={ styles.section }>
      <h2>{LABELS.TITLE}</h2>
      {docs.map((doc, index) => {
        const {
          type,
          fullName,
          fullNameTransliteration,
          number,
        } = doc;

        return (
          <div key={ index } className={ styles.document }>
            <h3>{type}</h3>
            <div className={ styles['info-row'] }>
              <label>{LABELS.NUMBER}</label>
              <span>{number}</span>
            </div>
            <div className={ styles['info-row'] }>
              <label>{LABELS.FULL_NAME}</label>
              <span>{fullName}</span>
            </div>
            <div className={ styles['info-row'] }>
              <label>{LABELS.FULL_NAME_TRANSLITERATION}</label>
              <span>{fullNameTransliteration}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Documents.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Documents;
