import React, { Component } from 'react';
import PropTypes from 'prop-types';

import decline from '../../../bi/utils/decline';
import { ACCOUNTTEXT, COMPANIESLIST } from '../../../bi/constants/account';

import LinkToNewTab from '../../../components/LinkToNewTab';

import styles from './styles/accounts.module.scss';

const COUNTNOTHIDDENCOMPANIES = 4;

class AccountItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      toggleCompanies: false,
    };
  }

  toggleOtherCompanies = () => this.setState({
    toggleCompanies: !this.state.toggleCompanies,
  });

  renderToggleAllView = (companiesValue) => {
    const { toggleCompanies } = this.state;

    const text = !toggleCompanies ? `Ещё ${companiesValue} ${decline(companiesValue, COMPANIESLIST)} в аккаунте`
      : ACCOUNTTEXT.HIDECOMPANY;

    return (
      <div
        className={ styles.others }
        onClick={ this.toggleOtherCompanies }
      >
        <div className={ styles.text }>{ text }</div>
        <span className={ `arrow ${styles.arrow} ${toggleCompanies ? styles.open : ''}` } />
      </div>
    );
  };

  renderCompanies = () => {
    const { item: { Companies } } = this.props;
    const { toggleCompanies } = this.state;

    let list = [];

    if (Companies.length <= COUNTNOTHIDDENCOMPANIES || toggleCompanies) {
      list = Companies;
    } else {
      list = Companies.slice(0, 4);
    }

    return list.map(({ AccountId, CompanyId, CompanyName, ShortCompanyName }) => (
      <LinkToNewTab
        key={ `${CompanyId}` }
        to={ `/vac/${AccountId}/company/${CompanyId}/account/profile` }
      >
        <div className={ styles.list }>
          { ShortCompanyName || CompanyName } ({ CompanyId })
        </div>
      </LinkToNewTab>
    ));
  };

  render() {
    const { item: { Companies, Name, Id } } = this.props;

    const companiesValue = Companies.length > COUNTNOTHIDDENCOMPANIES ? Companies.length - COUNTNOTHIDDENCOMPANIES : 0;
    const othersCompaniesHtml = companiesValue ? this.renderToggleAllView(companiesValue) : null;
    const companiesHtml = this.renderCompanies();
    const nameHtml = Name || <span className={ styles.nodicated } > { ACCOUNTTEXT.NOINDICATED } </span>;

    return (
      <div className={ styles.content } >
        <div className={ styles.header }>
          { nameHtml } ({Id})
        </div>
        <div className={ styles.details }>
          { companiesHtml }
        </div>
        { othersCompaniesHtml }
      </div>
    );
  }
}

export default AccountItem;
