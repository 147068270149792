// высота верхней плашки
const PANELHEIGHT = 56;

/**
 * Делает прокрутку страницы вверх до указанного элемента.
 * @param {string} elem - Элемент, до которого осуществлять прокрутку.
 * @param {string} time - Время прокрутки в мс.
 */
const scrollToTop = (elem, time = 500) => {
  const step = ((Math.abs(elem.getBoundingClientRect().y) + PANELHEIGHT) * 1000) / (time * 60);

  const scroll = () => {
    const elemOffsetY = elem.getBoundingClientRect().y;

    if (elemOffsetY < PANELHEIGHT) {
      const leftToScroll = Math.abs(elemOffsetY - PANELHEIGHT);
      const scrollStep = leftToScroll > step ? step : leftToScroll;

      window.scrollBy(0, -scrollStep);
      window.requestAnimationFrame(scroll);
    }
  };

  window.requestAnimationFrame(scroll);
};

export default scrollToTop;
