import Api from '../../../../../bi/api';
import { ENUMS } from '../../../../../bi/constants/employee';
import useLoadData from '../../../hooks/useLoadData';


const useLoadEnums = () => {
  const loadEnums = async () => Api.Enums.getEnums([ENUMS.BONUS, ENUMS.CITIZENSHIP]);

  const { loading, data: enums, error } = useLoadData(loadEnums, []);

  return { loading, enums, error };
};

export default useLoadEnums;

