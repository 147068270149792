import renderComponents from './components';

const NAME = 'sw-vacation-account';

class VacationAccountModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    this.page = renderComponents(this.box, { context });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, VacationAccountModule);
}
