const HUMAN_DOC_TYPES = {
  DomesticPassport: 'Паспорт Гражданина',
  ForeignPassport: 'Загран.паспорт',
  BirthCertificate: 'Свидетельство о рождении',
};

const useDisplayingDocumentsData = documents => (
  documents.map(({ number, type, name, lastName, middleName, surname, patronymic, firstName }) => ({
    fullName: `${surname} ${name} ${patronymic}`,
    fullNameTransliteration: `${lastName} ${firstName} ${middleName}`,
    type: HUMAN_DOC_TYPES[type] || type,
    number,
  }))
);

export default useDisplayingDocumentsData;
